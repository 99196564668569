.slider:hover {
  opacity: 1 !important;
  background-color: $primary !important;
}
.slider::-webkit-slider-thumb {
  background: $primary !important;
}
.slider::-moz-range-thumb {
  background: $primary !important;
}
/* Styles for the slider thumb */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Required to style */
  appearance: none;
  background: $primary !important;
  border: none !important;
}
.slider::-moz-range-thumb {
  background: $primary !important;
  border: none !important;
}
.slider::-ms-thumb {
  background: $primary !important;
}
/* Styles for the active part of the track (left side of the thumb) */
// .slider::-webkit-slider-runnable-track {
//   -webkit-appearance: none;
//   background: $primary !important;
// }
.slider::-webkit-range {
  -webkit-appearance: none;
  background: $primary !important;
}
.slider::-moz-range-progress {
  background: $primary !important;
}
