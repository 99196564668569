.sliding-div {
  background-color: $primary-gray;
  height: 100vh;
  margin-bottom: 100vh;
  position: relative;
  z-index: 1;
}

.ask-chloee-form {
  background-color: $primary-gray;
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
  top: 0;
}

.chloee-right-section {
  height: 110vh;
  object-fit: fill;
  position: fixed;
  background-color: $primary-black;
  right: 0;
  top: 0;
}

.chloee-logo-large {
  width: 200px;
}

.closed-eye-img {
  width: 60px;
  object-fit: contain;
  @media screen and (min-width: 768px) {
    width: 80px;
  }
}

.form-heading {
  text-align: justify;
}

/* styling the scrollbar track (the part the thumb moves along) */
.ask-chloee-form::-webkit-scrollbar-track {
  background-color: transparent;
}

/* styling the scrollbar thumb (the part that's draggable) */
.ask-chloee-form::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 5px;
}

/* styling the scrollbar itself (width, etc.) */
.ask-chloee-form::-webkit-scrollbar {
  width: 5px;
}

.finger-print-img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.chevron-down-container {
  cursor: pointer;
}

.chevron-down-img {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
  font-size: 30px !important;
  color: $black !important;
}

@keyframes mover {
  0% {
    transform: translateY(-10);
  }
  100% {
    transform: translateY(-30px);
  }
}

.fields-label {
  color: $black !important;
  white-space: nowrap;
  font-size: 10.125px;
  line-height: 22.5px;
  @media (min-width: 576px) {
    font-size: 12px;
    line-height: 25.313px;
  }
}

.btn-custom {
  color: $white;
  background-color: #bb4949 !important;
}

.btn-custom:hover {
  color: $white;
  opacity: 0.8;
  background-color: #bb4949 !important;
}

.btn-custom:disabled {
  color: $white;
}

.thumbs-up-down-img {
  width: 20px;
  height: 20px;
  opacity: 0.6;
}

.thumbs-up-down-img:hover {
  opacity: 1;
}

.input-field-button {
  font-size: 20px;
  padding-block: 2px !important;
  padding-inline: 10px !important;
  background-color: $primary-pink !important;
  height: 30px;
  &:hover {
    background-color: $primary-pink !important;
    opacity: 0.8;
  }
}
